@import-normalize;

@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/skel';

// NOTE: This import was moved into index.html template
// @import url('//fonts.googleapis.com/css?family=Source+Sans+Pro:400,700|Raleway:400,800,900');

/*
	Future Imperfect by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

	@include skel-breakpoints((
		xlarge: '(max-width: 1680px)',
		large: '(max-width: 1280px)',
		medium: '(max-width: 980px)',
		small: '(max-width: 736px)',
		xsmall: '(max-width: 480px)'
	));

	@include skel-layout((
		reset: 'full',
		boxModel: 'border',
		grid: ( gutters: 1em )
	));

// Base.

	@import 'base/page';
	@import 'base/typography';

// Component.

	@import 'components/author';
	@import 'components/blurb';
	@import 'components/box';
	@import 'components/button';
	@import 'components/form';
	@import 'components/icon';
	@import 'components/image';
	@import 'components/list';
	@import 'components/mini-post';
	@import 'components/post';
	@import 'components/section';
	@import 'components/table';
	@import 'components/hamburger';
	@import 'components/markdown';


// Layout.

	@import 'layout/header';
	@import 'layout/wrapper';
	@import 'layout/main';
	@import 'layout/sidebar';
	@import 'layout/intro';
	@import 'layout/footer';
	@import 'layout/menu';

	@import 'pages/contact';
	@import 'pages/notFound';
	@import 'pages/resume';
	@import 'pages/skills';
	@import 'pages/stats';

.github-stats-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
  width: 100%;
  
  .stats-row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;
  }

  .github-stat-card {
    max-width: 495px;
    width: 100%;
    height: auto;
    
    @media (max-width: 768px) {
      max-width: 100%;
    }
  }

  .language-card {
    max-width: 380px;
  }
}

.cell-container {
  width: 100%;
  height: 100%;
  min-width: 300px;
  max-width: 400px;
  margin: 0 auto;

  .mini-post {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;

    header {
      padding: 1rem;
      border-bottom: 1px solid #eee;

      h3 {
        margin: 0 0 0.5rem 0;
        font-size: 1.2rem;
        
        a {
          color: #3c3b3b;
          text-decoration: none;
          
          &:hover {
            color: #2ebaae;
          }
        }
      }

      .published {
        color: #666;
        font-size: 0.9rem;
      }
    }

    .project-content {
      display: flex;
      flex-direction: column;

      .image {
        width: 100%;
        height: 250px;
        overflow: hidden;
        
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.3s ease;

          &:hover {
            transform: scale(1.05);
          }
        }
      }

      .description-container {
        padding: 1rem;
        background: #f8f9fa;
        border-top: 1px solid #eee;
        flex-grow: 1;

        p {
          margin: 0;
          font-size: 0.95rem;
          line-height: 1.5;
          color: #666;
        }
      }
    }
  }
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 2rem 0;
  
  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}
